import React, { useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { baseUrl } from "services/api";
import { CloseButton } from "components/CloseButton";

export const LabelModal = ({ id, openModal, setOpenModal }) => {
  const [count, setCount] = useState(1);

  return (
    <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
      <ModalHeader>
        <div className="modal-title-text">Печать этикеток</div>
        <CloseButton onClick={() => setOpenModal(false)} />
      </ModalHeader>
      <ModalBody className="m-3">
        <Form action={`${baseUrl}/labels.print`} target="_blank">
          <Input type="hidden" name="order" value={id} />

          <FormGroup>
            <Label>Кол-во мест</Label>
            <Input
              type="number"
              name="count"
              value={count}
              onChange={(e) => setCount(e.target.value)}
            />
          </FormGroup>

          <FormGroup check inline>
            <Label>
              <Input type="radio" id="a4" name="format" label="a4" value="a4" />
              a4
            </Label>
          </FormGroup>

          <FormGroup check inline>
            <Label>
              <Input type="radio" id="a5" name="format" label="a5" value="a5" />
              a5
            </Label>
          </FormGroup>
          <FormGroup>
            <Button type="submit" color="primary" size="lg">
              Печать
            </Button>
          </FormGroup>
        </Form>

        <div className="d-flex justify-content-end">
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Закрыть
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
