import { AutocompleteArticle } from "features/Dashboard/AutocompleteArticle";
import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

import { CreateNomenclatureModal } from "components/CreateNomenclatureModal";
import { CloseButton } from "components/CloseButton";

export const SetNomenclatureModal = ({
  product,
  products,
  updateData,
}) => {
  const [article, setArticle] = useState("");
  const [brand, setBrand] = useState("");
  const [name, setName] = useState("");
  const [nomenclature, setNomenclature] = useState("");
  const [needValid, setNeedValid] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const setNomenclatureHandler = () => {
    const row = products[product.index - 1];
    row.nomenclature = {
      id: nomenclature,
      name,
      article,
      brand,
    };

    const newProducts = [...products];
    newProducts.splice(product.index - 1, 1, row);
    updateData(newProducts);

    setOpenModal(false);

    setArticle("");
    setBrand("");
    setName("");
    setNomenclature("");
  };

  return (
    <>
      <Button onClick={() => setOpenModal(true)}>Выбрать</Button>
      <Modal isOpen={openModal} toggle={() => setOpenModal(false)}>
        <ModalHeader>
          <div className="modal-title-text">Выбрать номенклатуру</div>
          <CloseButton onClick={() => setOpenModal(false)} />
        </ModalHeader>
        <ModalBody className="m-3">
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label>Артикул</Label>
                <ButtonGroup style={{ width: "100%" }}>
                  <AutocompleteArticle
                    article={article}
                    brand={brand}
                    setArticle={setArticle}
                    setBrand={({ id, name }) => setBrand(name)}
                    setName={setName}
                    setNomenclature={setNomenclature}
                    needValid={needValid}
                    style={{ width: "218px" }}
                  />
                  <CreateNomenclatureModal
                    articleHandler={setArticle}
                    brandHandler={(brand) => setBrand(brand?.name)}
                    nameHandler={setName}
                    nomenclatureHandler={setNomenclature}
                  />
                </ButtonGroup>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <Label>Бренд</Label>
                <Input
                  name="brand"
                  placeholder="Бренд"
                  value={brand}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <Label>Наименование</Label>
                <Input
                  name="name"
                  placeholder="Наименование"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>

          <div className="d-flex justify-content-end">
            <Button
              onClick={setNomenclatureHandler}
              color="primary"
              className="button-wide"
            >
              Выбрать
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
